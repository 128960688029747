<template>
  <section id="banner" data-aos="fade-up">
    <div class="container">
      <div class="row">
        <div class="col-6 col-sm-12 col-md-12">
          <h3>Speed-up your data access with synthetic data</h3>
          <p>
            ...generate and share data with exceptional accuracy and privacy
          </p>
          <div>
            <a
              href="https://calendly.com/synxlabs/30min?month=2022-09"
              class="btn bg-linear"
              >Request Demo</a
            >
          </div>
        </div>
        <div class="col-6 col-sm-12 col-md-12">
          <div class="preview">
            <img src="/images/9viJ.gif" alt="preview" width="300" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeBanner",
};
</script>

<style lang="scss" scoped>
#banner {
  // background: #03060b;
  background-size: cover;
  background-image: url("/images/banner-bg.svg");
  background-position: top right;
  background-repeat: no-repeat;
  padding: 50px 70px;
  min-height: 500px;

  h3,
  p {
    color: #ffffff;
  }

  h3 {
    margin-bottom: 41px;
  }

  p {
    margin-bottom: 49px;
  }

  .col-6 {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .preview {
    width: 530px;
    height: 445px;
    position: relative;
    z-index: 3;

    &:after {
      content: "";
      background: url("/images/ellipse.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 991px) {
    padding: 25px 35px;
    text-align: center;

    .row {
      flex-direction: column-reverse;
    }

    .preview {
      max-width: 100%;
      max-height: 350px;
      margin: auto;
    }

    .btn {
      margin: 10px auto;
    }

    h3 {
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 35px;
    }
  }
}
</style>
