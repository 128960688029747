<template>
  <footer>
    <div class="container footer">
      <div class="row justify-between">
        <div class="col-4 col-sm-11 col-md-11">
          <div class="row">
            <div class="col-3 text-center">
              <img src="/images/logo.png" alt="Logo" width="65" height="60" />
            </div>
            <div class="col-9">
              <p>
                Synx is a platform that guarantees both businesses and
                individuals access to clean data, with a guarantee of data
                privacy.
              </p>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="col-1 col-sm-2 col-md-3">
          <h6>COMPANY</h6>
          <ul>
            <li><a href="/">Terms of Use</a></li>
            <li><a href="/">Privacy Policy</a></li>
            <li>
              <a href="https://calendly.com/synxlabs/30min?month=2022-09">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
        <!--  -->
        <div class="col-2 col-sm-2 col-md-3">
          <h6>HELP CENTER</h6>
          <ul>
            <li><a href="/">Why Synx?</a></li>
            <li><a href="/">FAQs</a></li>
          </ul>
        </div>
        <!--  -->
        <div class="col-3 col-sm-6 col-md-5">
          <h6>CONTACT INFO</h6>
          <ul>
            <li><a href="tel:2348180458189">Phone: +234 818 045 8189</a></li>
            <li><a href="mailto:rectanglenet@gmail.com">Email: rectanglenet@gmail.com</a></li>
            <li>Location: 17, Divine Street, Lagos Nigeria</li>
          </ul>
          <div class="social-icons">
            <a href="https://www.linkedin.com/company/usesynx/"
              ><img
                src="/images/icons/icon-linkedin.png"
                alt="linkedin"
                width="24"
                height="24"
            /></a>
            <!-- <a href="#"
              ><img
                src="/images/icons/icon-facebook.png"
                alt="facebook"
                width="24"
                height="24"
            /></a> -->
            <a href="https://twitter.com/usesynx"
              ><img
                src="/images/icons/icon-twitter.png"
                alt="twitter"
                width="24"
                height="24"
            /></a>
            <a href="https://instagram.com/usesynx"
              ><img
                src="/images/icons/icon-instagram.png"
                alt="instagram"
                width="24"
                height="24"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="container copyright">
      <div class="row justify-between">
        <div class="col">
          <p>© 2022 All rights reserved</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "HomeFooter",
};
</script>

<style lang="scss" scoped>
footer {
  background: #000010;

  .container {
    padding: 0px 20px;
    &.footer {
      padding: 80px 20px 25px 20px;
      color: white;

      .row > div {
        margin-bottom: 10px;
      }

      h6 {
        font-weight: 700;
        font-size: 17px;
        line-height: 150%;
        letter-spacing: -0.03em;
        color: #f5f5f5;
        margin-bottom: 20px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: justify;
        color: #f5f5f5;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin-bottom: 7px;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          letter-spacing: -0.03em;
          color: #f5f5f5;

          &:hover {
            color: #f5f5f559;
          }
        }
      }

      a {
        color: inherit;
        text-decoration: none;
      }

      .social-icons {
        margin-top: 30px;
        a {
          margin-right: 24px;
        }
      }
    }

    &.copyright {
      border-top: 1px solid #f5f5f559;
      p {
        margin: 26px 0px;
        color: #f5f5f5;
      }
    }
  }

  @media (max-width: 1200px) {
    // display: none;
    .footer {
      // .col-4 {}
      // .col-1 {}
      // .col-2 {}
      // .col-3 {}
    }
  }
}
</style>
