<template>
  <section id="why-synx">
    <div class="container">
      <div class="row justify-center">
        <div class="col-10 col-sm-12 text-center">
          <h6>WHY Synx</h6>
          <h4>Generate Healthy Data</h4>
          <p>
            Synx guarantees both businesses and individuals access to clean
            data, with a guarantee of data privacy.
            <br />
            Enrich your ground training data with an endless variety of
            synthetic data and expose your models to more data than could
            otherwise be gathered.
          </p>
        </div>
      </div>

      <div class="row justify-center">
        <div class="col-10 col-sm-12">
          <div class="row">
            <div
              class="col-4 col-sm-12 col-md-6 col-lg-4 d-flex"
              v-for="(why, index) in whys"
              :key="index"
            >
              <div class="why-card">
                <div class="overlay">
                  <div class="icon bg-linear" data-aos="zoom-out">
                    <img :src="why.icon" alt="icon" width="150" height="150" />
                  </div>
                </div>

                <div class="why-card-header">
                  <div class="icon bg-linear" data-aos="zoom-out">
                    <img :src="why.icon" alt="icon" width="30" height="30" />
                  </div>
                  <h5 data-aos="fade-in">{{ why.title }}</h5>
                </div>
                <div class="why-card-body" data-aos="fade-in">
                  <p v-html="why.description"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeWhySynx",

  data: () => {
    return {
      whys: [
        {
          icon: "/images/icons/icon-privacy.png",
          title: "Data Privacy",
          description:
            "We provide businesses with a secure platform to work across teams with our synthetically generated data which has close accuracy to real-life data.",
        },
        {
          icon: "/images/icons/icon-performance.png",
          title: "Improve Model Performance",
          description:
            "Provide correct, precise and up to date data set to be consumed by your models",
        },
        {
          icon: "/images/icons/icon-model-bias.png",
          title: "Evade Model Bias",
          description:
            "Minimize model bias due to unbalanced data sets while maintaining privacy.",
        },
        {
          icon: "/images/icons/icon-data-augmentation.png",
          title: "Data Augmentation",
          description:
            "Increase the amount of data by generating more data point from exosting data/ your sample data",
        },
        {
          icon: "/images/icons/icon-prototyping.png",
          title: "Prototyping",
          description:
            "Speed up experimentation and prototyping by reducing data collection, organization and annotation latency.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#why-synx {
  padding: 48px 10px;

  h6 {
    color: #13166a;
    margin-bottom: 10px;
  }
  h4 {
    color: #333333;
    margin-bottom: 24px;
  }
  p {
    line-height: 150%;
    margin-bottom: 40px;
  }

  .why-card {
    padding: 10px 15px;
    margin: 5px;
    margin-bottom: 30px;
    position: relative;
    z-index: 2;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      background: #ffffff;
      // border: 1px solid grey;
      display: flex;
      justify-content: center;
      // align-items: center;

      .icon {
        width: 120px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 70%;
        }
      }
    }

    &:hover {
      .overlay {
        display: none;
      }
    }

    @media (max-width: 991px) {
      .overlay {
        display: none;
      }
    }
    
    .icon {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      padding: 7px;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      img {
        width: 100%;
        height: auto;
      }
    }
    .why-card-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      h5 {
        margin-left: 10px;
        font-size: 18px;
      }
    }
    .why-card-body {
      p {
        width: 95%;
      }
    }
  }
}
</style>
