<template>
  <section id="ready">
    <div class="container">
      <div class="row justify-center">
        <div class="col-8 col-sm-12 col-md-10 text-center">
          <h4>Ready to access clean data faster with a guaranteed privacy?</h4>
          <p>
            <a
              href="https://calendly.com/synxlabs/30min?month=2022-09"
              class="btn bg-linear"
              style="margin: auto"
            >
              Request a Demo
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeReady",

  data: () => {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#ready {
  padding: 0 25px;
  .container {
    padding: 48px 15px;
    border-radius: 10px 10px 0px 0px;
    // background: linear-gradient(
    //     0deg,
    //     rgba(51, 51, 51, 0.9),
    //     rgba(51, 51, 51, 0.9)
    //   ),
    //   url("/images/ready-bg.png");
    background: url("/images/ready-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  h4 {
    color: #ffffff;
    margin-bottom: 40px;
  }
}
</style>
