<template>
  <section id="how-it-works">
    <div class="container">
      <div class="row justify-center">
        <div class="col-10 col-sm-12 text-center">
          <h6>HOW IT WORKS</h6>
          <h4>Build Up Model Performance</h4>
          <p>
            Our system is designed to support real-world data augmentation or to
            create synthetic data entirely from scratch. Improve model
            performance by achieving the desired data distribution
          </p>
        </div>
      </div>
      <!--  -->

      <div class="row justify-center">
        <div class="col-10 col-sm-12 col-md-11">
          <div class="row justify-between">
            <div
              class="col col-sm-12 how-card-col"
              v-for="(how, index) in hows"
              :key="index"
            >
              <div class="how-card">
                <div class="how-card-header bg-linear text-center">
                  <h5>{{ how.title }}</h5>
                </div>
                <div class="how-card-body" data-aos="fade-in">
                  <p v-html="how.description"></p>
                  <a href="#">learn more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeWhySynx",

  data: () => {
    return {
      hows: [
        {
          title: "Accelerate",
          description:
            "Our platform is uniquely equipped with the core algorith needed to increase your data generation speed",
        },
        {
          title: "Classify",
          description:
            "Train machine learing model on your data and generate equivalent synthetic data.",
        },
        {
          title: "Transform",
          description:
            "Auto-organize your data by relevant categories, saves you the time and process of cleaning real life data.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#how-it-works {
  padding: 45px 10px;

  h6 {
    color: #cb3066;
    margin-bottom: 10px;
  }
  h4 {
    color: #333333;
    margin-bottom: 32px;
  }
  p {
    line-height: 150%;
    margin-bottom: 40px;
  }

  .col.how-card-col {
    flex: 0 1 30%;
    width: 30%;
    display: flex;
  }

  .how-card {
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .how-card-header {
      border-radius: 10px;
      padding: 14px 10px;
      h5 {
        color: #ffffff;
      }
    }
    .how-card-body {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 10px;
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-bottom: 15px;

      p {
        margin-bottom: 15px;
        flex: 1;
      }
      a {
        color: #16bffd;
        text-decoration: none;
        font-size: 12px;
      }
    }
  }
}
</style>
