<template>
  <section id="process">
    <div class="container">
      <div class="row justify-center">
        <div class="col-9 col-sm-12 col-md-10 text-center">
          <div class="icon">
            <img src="/images/icons/icon-process.png" alt="process" />
          </div>
        </div>
      </div>
      <div class="row justify-center">
        <div class="col-9 col-sm-12 col-md-10 text-center">
          <div class="process-description">
            <p>
              The process below involves you
              <b>identifying the type of data you would like to generate</b> on
              our platform, we run your request, then we synthetically generate
              data with close accuracy to your real-life data.
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-center">
        <div class="col-9 col-sm-12 col-md-10">
          <div class="row justify-between">
            <div class="col-3 col-sm-12 col-md-4">
              <div class="process-card bg-blue">
                <div class="process-card-body text-center">
                  <h5>Define Synthetic Data</h5>
                </div>
              </div>
            </div>
            <div class="col-3 col-sm-12 col-md-4">
              <div class="process-card bg-blue">
                <div class="process-card-body text-center">
                  <h5>Generate Synthetic Data</h5>
                </div>
              </div>
            </div>
            <div class="col-3 col-sm-12 col-md-4">
              <div class="process-card bg-blue">
                <div class="process-card-body text-center">
                  <h5>Review Synthetic Data</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  -->

      <div class="row justify-center">
        <div class="col-9 col-sm-12 col-md-10 text-center">
          <div class="icon">
            <img src="/images/icons/icon-process.png" alt="process" />
          </div>
        </div>
      </div>
      <div class="row justify-center">
        <div class="col-9 col-sm-12 col-md-10 text-center">
          <div class="process-description">
            <p>
              The process below involves you
              <b>uploading a sample of your real-world data</b> on our platform,
              we run your request, then we synthetically generate more data for
              you with close accuracy to your real-life data.
            </p>
          </div>
        </div>
      </div>

      <div class="row justify-center">
        <div class="col-9 col-sm-12 col-md-10">
          <div class="row justify-between">
            <div class="col-3 col-sm-12 col-md-4">
              <div class="process-card bg-pink">
                <div class="process-card-body text-center">
                  <h5>Upload real-world data</h5>
                </div>
              </div>
            </div>
            <div class="col-3 col-sm-12 col-md-4">
              <div class="process-card bg-pink">
                <div class="process-card-body text-center">
                  <h5>Augment and generate synthetic data</h5>
                </div>
              </div>
            </div>
            <div class="col-3 col-sm-12 col-md-4">
              <div class="process-card bg-pink">
                <div class="process-card-body text-center">
                  <h5>Review Synthetic Data</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  -->
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeProcess",

  data: () => {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#process {
  padding: 48px 10px;

  .row {
    margin-bottom: 48px;
  }

  .icon {
    display: inline-flex;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background: #333333;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;

    img {
      width: 31px;
      height: auto;
    }
  }

  .process-description {
    background: #fcfcfc;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 12px 50px;
    margin-top: 27px;
    margin-bottom: 48px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .process-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    padding: 10px 20px;
    margin: 10px;
    position: relative;
    z-index: 2;
    box-sizing: border-box;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 3;
      background: url("/images/process-bg.png");
      background-repeat: no-repeat;
      background-size: 110%;
      background-position: -3px -3px;
    }

    .process-card-body {
      color: #ffffff;
      z-index: 5;

      h5 {
        font-size: 20px;
      }
    }
  }
}
</style>
