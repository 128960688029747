<template>
  <div class="home">
    <nav-bar />
    <home-banner />
    <home-why-synx />
    <home-how-it-works />
    <home-process />
    <home-ready />
    <home-footer />
  </div>
</template>

<script>
// import HelloWorld from "@/components/HelloWorld.vue";
import NavBar from "../components/NavBar.vue";
import HomeBanner from "./HomeBanner.vue";
import HomeWhySynx from "./HomeWhySynx.vue";
import HomeHowItWorks from "./HomeHowItWorks.vue";
import HomeProcess from "./HomeProcess.vue";
import HomeReady from "./HomeReady.vue";
import HomeFooter from "../components/HomeFooter.vue";

export default {
  name: "Home",
  components: {
    NavBar,
    HomeBanner,
    HomeWhySynx,
    HomeHowItWorks,
    HomeProcess,
    HomeReady,
    HomeFooter,
  },
  mounted() {
    window.AOS.init();
  },
};
</script>
