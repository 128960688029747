<template>
  <nav>
    <div class="container">
      <div class="row">
        <div class="logo" data-aos="fade-right">
          <a href="/">
            <img src="/images/logo.svg" alt="" width="65" height="60" />
          </a>
        </div>

        <ul class="links" data-aos="fade-down">
          <li :class="{ active: $route.path == '/pricing' }">
            <a @click="$router.push('/pricing')">Pricing</a>
          </li>
          <li :class="{ active: $route.path == '/use-case' }">
            <a @click="$router.push('/use-case')">Use Cases</a>
          </li>
          <!-- <li><a href="#blog">Blog</a></li> -->
          <li>
            <a href="https://calendly.com/synxlabs/30min?month=2022-09">
              Contact Us
            </a>
          </li>
        </ul>

        <div class="actions" data-aos="fade-left">
          <a
            href="https://calendly.com/synxlabs/30min?month=2022-09"
            class="btn bg-linear"
          >
            Request Demo
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style lang="scss" scoped>
nav {
  height: 80px;
  padding: 20px 30px;
  box-sizing: border-box;
  position: sticky;
  z-index: 3;
  top: 0;
  right: 0;
  left: 0;
  background: white;

  .logo {
    img {
      width: 65px;
      height: 50px;
    }
  }

  .links {
    padding: 0;
    margin-left: 40px;
    text-decoration-line: none;

    li {
      display: inline-block;
      margin-right: 32px;
      color: #000000;
      font-size: 18px;
      font-weight: 600;

      &:hover,
      &.active {
        color: #16bffd;
      }

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  .actions {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 800px) {
    padding: 20px 10px;

    .logo {
      flex-basis: 20%;
    }

    .links {
      flex: 0 1 80%;
      text-align: right;
      margin-left: 0;
      li {
        margin-right: 10px;
      }
    }

    .actions {
      display: none;
    }
  }
}
</style>
