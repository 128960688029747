import { render, staticRenderFns } from "./HomeHowItWorks.vue?vue&type=template&id=355bf034&scoped=true&"
import script from "./HomeHowItWorks.vue?vue&type=script&lang=js&"
export * from "./HomeHowItWorks.vue?vue&type=script&lang=js&"
import style0 from "./HomeHowItWorks.vue?vue&type=style&index=0&id=355bf034&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "355bf034",
  null
  
)

export default component.exports